





















































































































































































































































































































































































































































































.icon-button {
  padding: 0 !important;
  font-size: 0.8rem;
}

.search-box {
  width: 100%;
  .vs-input {
    color: white;
  }
}
.vs-table__header {
  background-color: #0694a2;
  border-radius: 0;
  .vs-input-content {
    color: white;
    background-color: #0694a2;
  }
}

.vs-table {
  overflow: visible !important;

  td,
  th {
    width: 25%;
    margin: auto;
  }
}

#data-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-right: 1.5rem;
      margin-left: 1.5rem;
      > span {
        display: flex;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          color: white;
          font-size: 1rem;
          background-color: #008489;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      padding: 0 1rem;
      border-collapse: separate;
      border-spacing: 0 1.3rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: center;
            }

            .product-img {
              height: 110px;
            }
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          font-weight: 600;
          text-transform: uppercase;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
.Panel-StyledPanel {
  position: relative;
  //   grid-template-rows: repeat(2, auto);
  gap: 30px;
  background: rgb(255, 255, 255);
  //   border-radius: 4px;
  border: 1px solid rgb(198, 190, 207);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

#qrcode {
  svg {
    margin: auto;
  }
}
