








































































.AccommodationVTour .v-step {
  top: -1% !important;
  left: 70% !important;
}
.v-step,
.v-step__header {
  background: #ea5455 !important;
}
.AccommodationVTour .v-step__arrow {
  left: 80% !important;
  border-color: #ea5455 !important;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
}
